@use '../../styles/mixins' as mixins;

.bnpp-button {
	@include mixins.button;

	// Hide the injected a tag
	&.block-editor-rich-text__editable {
		text-decoration: none!important;
		a {
			display: none;
		}
	}
}